import request from '@/utils/requestV2'
const qs = require('qs')

// 获取电子屏规则配置
export function getElectricRule (data) {
  return request({
    url: '/ooh-product/electricProducts/get',
    method: 'post',
    data: qs.stringify(data)
  })
}
/**
 * 获取电子屏规则配置
 * @param {Object} data
 * @returns
 */
export function list (data) {
  return request({
    url: '/ooh-product/electricProducts/list',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 新增电子屏规则配置
 * @param {Object} data
 * @returns
 */
export function add (data) {
  return request({
    url: '/ooh-product/electricProducts/add',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 编辑电子屏规则配置
 * @param {Object} data
 * @returns
 */
export function edit (data) {
  return request({
    url: '/ooh-product/electricProducts/edit',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 删除电子屏规则配置
 * @param {Object} data
 * @returns
 */
export function delSetting (data) {
  return request({
    url: '/ooh-product/electricProducts/delete',
    method: 'post',
    data: qs.stringify(data)
  })
}
