import request from '@/utils/requestV2'
const qs = require('qs')

// 获取产品单品
export function getProductAndSkuList (data) {
  return request({
    url: '/ooh-product/v1/productsku/getpage',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取媒介组包资源详情按资源所在层数分组
export function getProductskuFixResource (data) {
  return request({
    url: '/ooh-product/v1/productsku/getproductskufixresource',
    method: 'POST',
    data: qs.stringify(data)
  })
}
