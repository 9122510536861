<template>
    <Modal v-model="showResource" width="1000" title="自定义资源采购" footer-hide :mask-closable="false">
        <Row :gutter="8" class="p-b-10">
          <i-col span="4">
            <i-select size="small" v-model="Chkschedules" multiple  placeholder="请选择档期">
                <i-option v-for="(item,index) in schedules" :value="item.startDate+'_'+item.endDate" :key="index">{{ getScheduleInfo(item.startDate,item.endDate) }}</i-option>
            </i-select>
          </i-col>
          <i-col span="4">
            <i-select size="small" v-model="currentAsset" placeholder="请选择资产" @on-change="loadProductList">
              <i-option v-for="asset in assets" :key="asset.value" :value="asset.value">{{asset.label}}</i-option>
            </i-select>
          </i-col>
          <i-col span="3">
             <i-select size="small" v-model="currentStation" filterable  placeholder="请选择站台" clearable>
              <i-option v-for="station in stations" :key="station.id" :value="station.id">{{station.name}}</i-option>
            </i-select>
          </i-col>
          <i-col span="3">
             <i-select size="small" v-model="currentProductId" clearable placeholder="请选择产品" @on-change="handleChangeProduct">
              <i-option v-for="product in productList" :key="product.id" :value="product.id">{{product.name}}</i-option>
            </i-select>
          </i-col>
          <!-- <i-col span="3" v-if="resourceTypeList.length>1">
             <i-select size="small" placeholder="请选择资源类型" v-model="currentResourceTypeIds" multiple clearable>
               <i-option v-for="resourceType in resourceTypeList" :key="resourceType.id" :value="resourceType.id">{{resourceType.name}}</i-option>
            </i-select>
          </i-col> -->
          <i-col span="5">
             <i-input size="small" placeholder="资源编号/站点名称" v-model='query.keyword'></i-input>
          </i-col>
          <i-col span="2">
             <Button size="small" icon="ios-search" type="primary" @click="searchData">搜索</Button>
          </i-col>
        </Row>

        <Table stripe  :columns="columns"  :data="data" size="small"></Table>
        <div class="paging_style">
          <Page size="small" :total="total" :page-size="query.pageSize" :current="query.pageNumber" show-total show-elevator  @on-change="changePage" ></Page>
        </div>

        <Spin fix size="large" v-if="spinShow">
          <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
          <div>资源生成中...</div>
        </Spin>
    </Modal>
</template>

<script>
import { getStationByAsset } from '@/api/product/station'
import { getProductResourcePage } from '@/api/product/productresource'
import { addResource, deleteResource } from '@/api/order/purchase'
import { getAllProduct } from '@/api/product/product'

import { GetCurrentSchedule } from '@/utils/dateFormat'

export default {
  props: {
    orderId: {
      type: [Number, String],
      default: ''
    },
    assets: {
      type: Array,
      default: null
    },
    schedules: { type: Array },
    checkSchedules: { type: [String, Array] }, // 选中的采购档期
    productId: { type: Number },
    categoryId: { type: Number },
    allowProductList: { type: Array },
    duration: {
      type: Number,
      default: 0
    },
    period: {
      type: Number,
      default: 0
    },
    stationId: { type: [String, Number] }
  },
  data () {
    return {
      spinShow: false,
      showResource: false,
      currentAsset: '', // 当前资产
      currentStation: '', /// 当场站点
      currentProductId: '', // 当前产品
      currentResourceTypeIds: [], // 当前选中的资源类型ID

      total: 0,
      query: {
        pageSize: 10,
        pageNumber: 1,
        // productId: null,
        productIds: '',
        resourcetypeIds: '',
        orderId: this.orderId,
        keyword: '',
        sold: 0
      },

      columns: [
        { title: '资源编号', width: 150, key: 'code' },
        { title: '资源等级', key: 'stationLevelName' },
        { title: '所属站台', key: 'stationName' },
        { title: '所属楼层', width: 90, key: 'floor', mediaType: 0 },
        { title: '位置', key: 'positionName' },
        {
          title: '出口',
          render: (h, data) => {
            return h('span', data.row.exit === '' ? '-' : data.row.exit)
          }
        },
        { title: '类型', key: 'resourcetypeName' },
        {
          title: '操作',
          render: (h, data) => {
            if (!data.row.enabled) {
              return h('Button', { props: { type: 'ghost', size: 'small' } }, '不可用')
            } else if (data.row.isSold) {
              return h('Button', { props: { type: 'ghost', size: 'small' } }, '已售出')
            } else if (data.row.isOrderResource) {
              return h('a', {
                style: {
                  marginRight: '5px',
                  color: '#ef4f4f'
                },
                on: { click: () => { this.handleRemove(data.row) } }
              }, '移除')
            } else {
              return h('a', {
                on: { click: () => { this.handleAdd(data.row) } }
              }, '选择')
            }
          }
        }
      ],
      data: [],

      stationMap: new Map(),
      stations: [],
      Chkschedules: [],
      reloadMap: false,
      productList: [],
      resourceTypeList: [] // 资源类型清单
    }
  },
  methods: {
    showModal () {
      if (this.assets.length) {
        this.currentAsset = this.assets[0].value
      }
      this.query.pageNumber = 1
      this.showResource = true

      this.Chkschedules = JSON.parse(this.checkSchedules).map(res => {
        return res.startDate + '_' + res.endDate
      })

      this.currentProductId = this.productId
      this.productList = this.allowProductList
      this.resourceTypeList = this.productList.find(x => x.id === this.productId).resourcetypeList
      this.currentResourceTypeIds = []

      if (this.stationId) {
        this.currentStation = this.stationId
      }

      this.InitData()
    },
    handleChangeProduct (val) {
      // if (!val) {
      //   this.query.productIds = this.productList.map(x => x.id)
      // } else {
      //   this.query.productIds = [val]
      // }

      // this.resourceTypeList = this.productList.find(x => x.id === val).resourcetypeList

      // this.currentResourceTypeIds = []
    },
    searchData () {
      this.query.pageNumber = 1
      this.InitData()
    },
    InitData () {
      const _this = this
      _this.query.assetIds = JSON.stringify([this.currentAsset])
      _this.query.stationId = _this.currentStation
      // _this.query.productId = _this.currentProductId
      _this.query.resourcetypeIds = JSON.stringify(_this.currentResourceTypeIds)
      if (this.currentProductId) {
        _this.query.productIds = JSON.stringify([this.currentProductId])
      } else {
        _this.query.productIds = JSON.stringify(this.productList.map(x => x.id))
      }

      const sches = []
      if (_this.Chkschedules.length) {
        _this.Chkschedules.forEach(item => {
          const schedule = item.split('_')
          if (schedule.length) { sches.push({ startDate: schedule[0], endDate: schedule[1] }) }
        })
      } else {
        _this.schedules.forEach(item => {
          sches.push({ startDate: item.startDate, endDate: item.endDate })
        })
      }
      _this.query.schedules = JSON.stringify(sches)
      this.getData(_this.query)
    },
    getData (query) {
      getProductResourcePage(query).then(res => {
        if (res.list.length === 0 && query.pageNumber > 1) {
          this.query.pageNumber -= 1
          this.getData(this.query)
        } else {
          this.data = res.list
          this.total = res.totalRow
          this.query.pageNumber = (res.pageNumber > 0) ? res.pageNumber : 1
        }
      })
    },
    handleAdd (resource) {
      const _this = this
      _this.spinShow = true
      const addCondition = {
        orderId: _this.orderId,
        resourceId: resource.resourceId,
        schedules: _this.query.schedules,
        duration: this.duration > 0 ? this.duration : null,
        period: this.period > 0 ? this.period : null
      }

      addResource(addCondition).then(res => {
        if (res && !res.errcode) {
          _this.$Notice.success({ desc: '成功添加点位到订单' })
          _this.reloadMap = true
          _this.getData(_this.query)

          this.$store.commit('set_order_update_purchase', new Date())
          this.$emit('updateData')
        }

        _this.spinShow = false
      }).catch(() => {
        this.spinShow = false
      })
    },
    handleRemove (resource) {
      let postData = null
      const sches = []
      const deleteResourcePromise = []

      if (this.Chkschedules.length) {
        this.Chkschedules.forEach(item => {
          const schedule = item.split('_')
          if (schedule.length) { sches.push({ startDate: schedule[0], endDate: schedule[1] }) }
        })
      } else {
        this.schedules.forEach(item => {
          sches.push({ startDate: item.startDate, endDate: item.endDate })
        })
      }

      sches.forEach(item => { // 根据选中的档期，批量删除点位, 后端不支持批量档期删除
        postData = {
          orderId: this.orderId,
          resourceId: resource.resourceId,
          startDate: item.startDate,
          endDate: item.endDate
        }
        deleteResourcePromise.push(deleteResource(postData))
      })
      Promise.all(deleteResourcePromise).then(() => {
        this.$Notice.success({ desc: '移除点位成功' })
        this.reloadMap = true
        this.getData(this.query)
      })
    },
    changePage (currentPage) {
      this.query.pageNumber = currentPage
      this.getData(this.query)
    },
    getScheduleInfo (startDate, endDate) {
      return GetCurrentSchedule(startDate, endDate)
    },
    loadProductList () {
      const postData = {
        categoryId: this.categoryId,
        assetIds: JSON.stringify([this.currentAsset])
      }

      getAllProduct(postData).then(res => {
        this.productList = res
        this.currentProductId = res[0].id
        this.InitData()
      })
    }
  },
  watch: {
    currentAsset: function () {
      if (this.currentAsset) {
        if (this.stationMap.has(this.currentAsset)) {
          this.stations = this.stationMap.get(this.currentAsset)
        } else {
          getStationByAsset({ assetId: this.currentAsset }).then(res => {
            this.stations = res
            this.stationMap.set(this.currentAsset, res)
          })
        }
      }
    }
  }
}
</script>
