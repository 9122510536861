<template>
  <div>
    <div v-if="hasElectric">
      <h4 class="p-b-5">投放规格：</h4>
      <Row>
        <i-col span="24" class="p-b-5">
          <RadioGroup v-model="selectDuration" type="button" size="small" >
            <Radio v-for="(item,index) in electricDurations" :key="index" :label="item">{{ item }} 秒</Radio>
          </RadioGroup>
        </i-col>
      </Row>
      <h4 class="p-b-5">投放次数：</h4>
      <Row>
        <i-col span="24" class="p-b-5">
          <RadioGroup v-model="selectPeriod" type="button" size="small" >
            <Radio v-for="(period,index) in electricPeriods" :key="index" :label="period">{{ period }} 次/天</Radio>
          </RadioGroup>
        </i-col>
      </Row>
    </div>

      <h4 class="p-b-5">指定资源位置（默认全选）</h4>
      <Row class="p-b-5">
          <i-col span="24">
              <div v-for="(position) in positions" :key="position.value" class="p-l-10 p-1-10"
                  :class="checkPositions.includes(position.value)?'workplatform-radio-button-active':'workplatform-radio-button'" @click="changeCheckPositions(position.value)"
                  >{{position.name}}</div>
          </i-col>
      </Row>

      <h4 class="p-b-5">站点周边关键字</h4>
      <Row>
          <i-col span="24">
              <i-input type="text" size="small" clearable v-model="GenerationCondition.keyword" placeholder="多关键字用逗号隔开,如：超市,商场,电影院" ></i-input>
          </i-col>
      </Row>
      <Row>
          <i-col span="16">
              <Slider v-model="GenerationCondition.distance" :max="1000" :step="50" @on-change="AdaptationRange" ></Slider>
          </i-col>
          <i-col span="8" class="p-t-10 p-l-5">适配范围( <span class="text-orange">{{ GenerationCondition.distance }}</span> 米)</i-col>
      </Row>

      <Divider dashed style="margin: 0;" class="p-l-20 p-r-20">
          <a style="font-size: xx-small" @click="handleChangeCondition">
              <span v-if="!showMoreCondition"><Icon type="md-arrow-round-down" />显示更多筛选条件<Icon type="md-arrow-round-down" /></span>
              <span v-else><Icon type="md-arrow-round-up" />点击收起筛选条件<Icon type="md-arrow-round-up" /></span>
          </a>
      </Divider>
      <Row v-show="showMoreCondition" v-for="level in levels" :key="level.value">
          <i-col span="24" >
              <label>指定包含 <strong class="text-orange">{{level.name}}</strong> 级站台范围：
                  <Checkbox v-model="level.isAllChecked" @on-change="checkAllStation(level.value,level.stations)">
                      <strong class="text-gray">全选</strong>
                  </Checkbox>
              </label>
              <div>
                  <Button v-for="station in level.stations" :key="station.value" :disabled="!station.hasProduct" :type="checkStationIds.includes(station.value)?'primary':'text'"  @click="changeCheckStations(station.value)"  size="small" style="margin:1px">{{station.name}}</Button>
              </div>
          </i-col>
      </Row>

      <Row :gutter="8">
          <i-col class="p-b-5" span="12" v-for="(sku,index) in skus" :key="index">
              <label><strong>{{sku.stationLevelName}}级：</strong> (屏幕数量 <strong class="text-orange">{{sku.max}}</strong>)</label>
              <InputNumber size="small" style="width:100%" :max="sku.max" :min="0" v-model="sku.quantity"></InputNumber>
          </i-col>
      </Row>
      <Row :gutter="8" class="p-t-10">
          <!-- <i-col span="9">
            <Button class="btn-black" size="small" long  @click="handleShowResource">地图显示点位余量</Button>
          </i-col> -->
          <i-col span="12">
            <Button type="success" size="small" long  @click="handleShowListPurchase">自定义资源采购</Button>
          </i-col>
          <i-col span="12" v-if="isAuth('order_modify_scheme')">
            <Button type="success" size="small" long :disabled="isSubmit" :loading="spinShow"  @click="handleGenerationResources">自动生成点位方案</Button>
          </i-col>
      </Row>
      <p class="p-t-5"><a @click="handleShowResource"><Icon type="md-refresh" />刷新地图点位余量</a></p>

      <ListPurchase v-if="query.allowLines.length>0" ref="customResource" :assets="query.allowLines" :orderId="query.orderId"
        :schedules="query.orderScheduleList" :checkSchedules="query.schedules"  :categoryId="query.categoryId" :productId="query.productId" :allowProductList="query.allowProductList"
        :duration="selectDuration" :period="selectPeriod" @updateData="ReloadStocks"></ListPurchase>

      <ListRecommend ref="listRecommend" @handlePlan1="beginGenerationResources"  @handlePlan2="beginAddResources"/>

  </div>
</template>

<script>
import ListPurchase from '../common/ListPurchase'
import ListRecommend from '../common/ListRecommend'

import { getProductAndSkuList } from '@/api/product/productsku'
import { getElectricRule } from '@/api/product/electricProducts'
import { stockQuantitys, getStationIdsByCondition } from '@/api/product/product'
import { getStationProduct, getStationResourceQuantity } from '@/api/product/productresource'
import { getPositions } from '@/api/product/resource'
import { adddtProductSkus, addOrderPlan } from '@/api/order/orderitem'
import { applyPlan } from '@/api/dw/recommend'

import { toMoney } from '@/utils/wnumb_own'

export default {
  components: {
    ListPurchase, ListRecommend
  },
  data () {
    return {
      query: {
        orderId: -1,
        assetIds: '', // 资产主键
        categoryId: null, // 产品类别主键
        productId: null,
        schedules: [], // 订单采购档期

        orderScheduleList: [], // 可用的全部档期
        allowProductList: [], // 全部允许的产品清单
        allowLines: [] // 全部的线路
      },
      defaultProduct: null, // 当前采购产品的默认档期

      spinShow: false,
      showMoreCondition: false,

      hasElectric: false, // 是否设置电子屏规则
      electricDurations: [],
      electricPeriods: [],
      selectDuration: null,
      selectPeriod: null,

      skus: [], // 可采购的产品类型（级别）
      levels: [],
      positions: [],
      checkPositions: [],
      checkStationIds: [], // 选中的站点集合
      checkAllStatus: [],
      productskuList: [], // 产品清单

      GenerationCondition: { // 生成资源的条件
        keyword: '',
        distance: 500
      },
      publisherId: this.$store.getters.token.userInfo.publisherId,
      recommend: this.$store.getters.token.publisherSetting.recommend // 是否开启资源推荐
    }
  },
  methods: {
    initPageData (searchBean, hasElectric) {
      this.query.orderId = searchBean.orderId
      this.query.assetIds = JSON.stringify(searchBean.assetIds) // 资产主键
      this.query.categoryId = searchBean.categoryId // 产品类别主键
      this.query.productId = searchBean.productId
      this.query.schedules = JSON.stringify(searchBean.schedules) // 订单采购档期

      this.query.allowProductList = searchBean.allowProductList
      this.query.allowLines = searchBean.allowLines
      this.query.orderScheduleList = searchBean.orderScheduleList

      this.defaultProduct = {
        productId: this.query.productId,
        startDate: searchBean.schedules[0].startDate,
        endDate: searchBean.schedules[0].endDate
      }

      this.hasElectric = hasElectric
      this.SeachResource()
    },
    SeachResource () {
      const main = {
        assetIds: this.query.assetIds,
        categoryId: this.query.categoryId,
        pageNumber: 1,
        pageSize: 999,
        orderId: this.query.orderId,
        productId: this.query.productId
      }

      getProductAndSkuList(main).then(res => {
      // 初始化所有字段数据
        this.levels = []
        this.checkStationIds = []
        this.checkPositions = []
        this.checkAllStatus = []
        this.GenerationCondition.keyword = ''
        this.GenerationCondition.distance = 500

        // 初始化查询条件
        this.skus = res.list.map(x => {
          x.quantity = 0
          x.price = toMoney(x.usePrice)
          return x
        })

        this.getResourcePostions()
        if (this.hasElectric) {
          this.getElectronicRules()
        }
      })
    },
    getResourcePostions () { // 获取可用的资源位置
      const queryData = {
        productId: this.query.productId,
        assetIds: this.query.assetIds
      }

      getPositions(queryData).then(res => {
        this.positions = res
        res.forEach(x => {
          this.checkPositions.push(x.value)
        })
      }).then(() => {
        this.getResourceStation()
      })
    },
    getElectronicRules () { // 获取电子屏采购规则
      const queryData = {
        productId: this.query.productId,
        publisherId: this.publisherId
      }

      getElectricRule(queryData).then(res => {
        this.electricDurations = res.durations.split(',').map(x => { return parseInt(x) })
        this.selectDuration = this.electricDurations.length > 0 ? this.electricDurations[0] : [null]

        this.electricPeriods = res.periods.split(',').map(x => { return parseInt(x) })
        this.selectPeriod = this.electricPeriods.length > 0 ? this.electricPeriods[0] : null

        // 给store赋值，地图采购会使用到
        this.$store.commit('set_order_purchase_duration', this.selectDuration)
        this.$store.commit('set_order_purchase_period', this.selectPeriod)
      })
    },
    getResourceStation () { // 获取可用的资源站点列表
      const data = {
        assetIds: this.query.assetIds,
        productId: this.query.productId,
        excludeOrderId: this.query.orderId,
        schedules: this.query.schedules
      }
      getStationProduct(data).then(res => {
        this.levels = []
        this.checkAllStatus = []
        res.forEach(asset => {
        // 循环添加级别和级别下面所属的站点集合
          if (asset.stationGroupLevelBeanList !== null && asset.stationGroupLevelBeanList.length > 0) {
            asset.stationGroupLevelBeanList.forEach(item => {
              if (this.levels.filter(level => level.value === item.stationLevel).length === 0) {
                this.levels.push({
                  isAllChecked: false,
                  name: item.stationLevelName,
                  value: item.stationLevel,
                  stations: item.stationBeanList.map(sta => {
                    return {
                      value: sta.stationId,
                      name: sta.stationName,
                      assetName: asset.assetName,
                      stationName: sta.stationName,
                      hasProduct: sta.hasProduct
                    }
                  })
                })
                this.checkAllStatus.push({ name: item.stationLevel, value: false })
              } else {
                const _level = this.levels.filter(level => level.value === item.stationLevel)[0]
                let station = null
                item.stationBeanList.map(sta => {
                // 验证时候已经存在相同的名称， 换乘站
                  station = _level.stations.find(x => x.stationName === sta.stationName)
                  if (station) {
                  // 修改原有站点名称
                    station.name = station.stationName + '(' + station.assetName + ')'
                    // 添加新的站点名称
                    _level.stations.push({ value: sta.stationId, name: sta.stationName + '(' + asset.assetName + ')', stationName: sta.stationName, hasProduct: sta.hasProduct, assetName: asset.assetName })
                  } else {
                    _level.stations.push({ value: sta.stationId, name: sta.stationName, stationName: sta.stationName, hasProduct: sta.hasProduct, assetName: asset.assetName })
                  }
                })
              }
            })
          }
        })
      }).then(() => {
        this.ReloadStocks()
      })
    },
    changeCheckPositions (name) { // 位置选中发生更改
      if (this.checkPositions.includes(name)) {
        const index = this.checkPositions.indexOf(name)
        this.checkPositions.splice(index, 1)
      } else {
        this.checkPositions.push(name)
      }
      this.ReloadStocks()
    },
    changeCheckStations (stationId) { // 站台选中发生更改
      if (this.checkStationIds.includes(stationId)) {
        const index = this.checkStationIds.findIndex(x => x === stationId)
        this.checkStationIds.splice(index, 1)
      } else {
        this.checkStationIds.push(stationId)
      }
      this.ReloadStocks()
    },
    checkAllStation (levelValue, levelStations) { // 全选可用的站点
      const _this = this
      const checkStatus = _this.checkAllStatus.filter(x => x.name === levelValue)[0]
      if (checkStatus.value) {
        levelStations.forEach(checkedSta => {
          const index = _this.checkStationIds.indexOf(checkedSta.value)
          if (index > -1) { _this.checkStationIds.splice(index, 1) }
        })
      } else {
        levelStations.forEach(checkedSta => {
          if (_this.checkStationIds.indexOf(checkedSta.value) === -1 && checkedSta.hasProduct) {
            _this.checkStationIds.push(checkedSta.value)
          }
        })
      }
      checkStatus.value = !checkStatus.value

      this.ReloadStocks()
    },
    AdaptationRange (val) { // 查询距离改变
      if (this.GenerationCondition.keyword.trim().length > 0) {
        this.initCheckedStations()
      }
    },
    initCheckedStations () { // 加载默认选中站台
      this.checkStationIds = []
      const data = {
        assetIds: this.query.assetIds,
        orderId: this.query.orderId,
        conditions: JSON.stringify(this.condition)
      }

      getStationIdsByCondition(data).then(res => {
        res.forEach(checkedSta => {
        // 判断当前站点是否在可用的清单内
          if (this.checkExsitStation(checkedSta)) {
            this.checkStationIds.push(checkedSta)
          }
        })
        // 地图闪烁站点
        const params = {
          data: this.checkStationIds,
          checkedSchedules: JSON.parse(this.query.schedules),
          assetIds: JSON.parse(this.query.assetIds),
          condition: this.condition
        }
        this.$emit('on-updated-map', 'flicker', params)
      }).then(() => {
        this.ReloadStocks(true)
      })
    },
    handleChangeCondition () {
      this.showMoreCondition = !this.showMoreCondition
      this.checkStationIds = []
      // 取消所有站点全选效果
      this.checkAllStatus.forEach(element => {
        element.value = false
      })
      this.levels.forEach(element => {
        element.isAllChecked = false
      })
    },
    checkExsitStation (stationId) {
      let flag = false
      let currentStationStatus
      for (let index = 0; index < this.levels.length; index++) {
        currentStationStatus = this.levels[index].stations.find(x => x.value === stationId)

        if (currentStationStatus && currentStationStatus.hasProduct) {
          flag = true
          break
        }
      }
      return flag
    },
    ReloadStocks (isFlicker) {
      const StockQuery = {
        assetIds: this.query.assetIds,
        orderId: this.query.orderId,
        productId: this.query.productId,
        schedules: this.query.schedules,
        positions: JSON.stringify(this.checkPositions),
        stationIds: JSON.stringify(this.checkStationIds),
        duration: this.selectDuration,
        period: this.selectPeriod
      }
      stockQuantitys(StockQuery).then(res => {
        res.forEach(stock => {
          const index = this.skus.findIndex(s => s.productskuId === stock.productSkuId)
          if (index !== -1) {
            let quantity = 0
            if (this.skus[index] && this.skus[index].quantity <= stock.quantity) {
              quantity = this.skus[index].quantity
            }
            this.skus.splice(index, 1, Object.assign({}, this.skus[index], { max: stock.quantity, quantity: quantity }))
          }
        })
      })

      // 更新地图显示站点和线路
      if (!isFlicker) {
        this.handleShowResource()
      }
    },
    handleShowResource () {
    // 查询当前订单已采购资源
      const searchResourceCondition = {
        excludeOrderId: this.query.orderId,
        assetIds: this.query.assetIds,
        productId: this.query.productId,
        schedules: this.query.schedules
      }

      getStationResourceQuantity(searchResourceCondition).then(res => {
        const params = {
          data: res,
          checkedSchedules: JSON.parse(this.query.schedules),
          assetIds: JSON.parse(this.query.assetIds),
          condition: this.condition
        }

        this.$emit('on-updated-map', 'full', params)
      })
    },
    handleGenerationResources () {
      if (this.recommend) {
        const postData = {
          orderId: this.query.orderId,
          productId: this.query.productId,
          productskus: this.productsku,
          schedules: this.query.schedules,
          assetIds: this.query.assetIds,
          conditions: JSON.stringify(this.condition),
          positions: JSON.stringify(this.checkPositions),
          stationIds: JSON.stringify(this.checkStationIds),
          duration: this.selectDuration,
          period: this.selectPeriod
        }

        this.$refs.listRecommend.initData(postData)
      } else {
        this.beginGenerationResources()
      }
    },
    beginGenerationResources () {
      this.spinShow = true

      const addResourceBean = {
        assetIds: this.query.assetIds, // 采购的产品ID
        orderId: this.query.orderId, // 采购ID
        productskus: this.productsku, // 采购的数量集合
        schedules: this.query.schedules,
        conditions: JSON.stringify(this.condition),

        duration: this.selectDuration,
        period: this.selectPeriod,

        positions: JSON.stringify(this.checkPositions), // 资源位置
        stationIds: JSON.stringify(this.checkStationIds) // 站台集合
      }

      adddtProductSkus(addResourceBean).then(res => {
        if (res && !res.errcode) {
          this.$Notice.success({
            desc: '添加资源成功,请在页面右侧【采购清单】中查看购买详情！'
          })

          // 默认选中编辑状态
          this.$emit('on-update-order', this.defaultProduct)

          // 刷新可用生成数量
          this.ReloadStocks(false)
          this.skus.forEach(element => {
            element.quantity = 0
          })
        }
        this.spinShow = false
      }).catch(() => {
        this.spinShow = false
      })
    },
    beginAddResources (planId) {
      this.spinShow = true
      const postData = {
        orderId: this.query.orderId,
        planId: planId,
        schedules: this.query.schedules,
        conditions: JSON.stringify(this.condition),
        duration: this.selectDuration,
        period: this.selectPeriod
      }

      addOrderPlan(postData).then(res => {
        if (res && !res.errcode) {
          this.$Notice.success({
            desc: '添加资源成功,请在页面右侧【采购清单】中查看购买详情！'
          })

          // 默认选中编辑状态
          this.$emit('on-update-order', this.defaultProduct)

          // 标识推荐为应用
          applyPlan({ planId: planId })

          // 刷新可用生成数量
          this.ReloadStocks(false)
          this.skus.forEach(element => {
            element.quantity = 0
          })
        }
        this.spinShow = false
      }).catch(() => {
        this.spinShow = false
      })
    },
    handleShowListPurchase () {
      this.$refs.customResource.showModal()
    },
    reload () {
      this.ReloadStocks(this.mapType === 'purchase')
    }
  },
  computed: {
    isSubmit: function () {
      let total = 0
      this.skus.forEach(element => {
        total += element.quantity
      })

      return total === 0
    },
    condition: function () {
      return {
        distance: this.GenerationCondition.distance,
        keywords: this.GenerationCondition.keyword
      }
    },
    mapType () {
      return this.$store.state.order.mapType
    },
    productsku () {
      const productSkuList = this.skus.map(sku => {
        return {
          productskuId: sku.productskuId,
          quantity: (sku.quantity === null) ? 0 : sku.quantity
        }
      })

      return JSON.stringify(productSkuList)
    }
  },
  watch: {
    'GenerationCondition.keyword' (val) {
      this.showMoreCondition = val !== ''

      this.initCheckedStations()
    },
    selectDuration (val) {
      this.$store.commit('set_order_purchase_duration', val)
    },
    selectPeriod (val) {
      this.$store.commit('set_order_purchase_period', val)
    }
  }
}
</script>
