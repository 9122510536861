<template>
    <Modal v-model="showRecommend" width="800" footer-hide :mask-closable="false">
        <p slot="header" >
            请选择所需智能推荐方案，有效期倒计时：
            <strong class="text-16" :class="cuurentCountdown==='已过期'?'text-red':'text-orange'">{{cuurentCountdown}}</strong>
        </p>
        <!-- <p class="p-b-5 p-l-5">
          <Button type="success" class="m-r-5" size="small" @click="handlePlan1">一键加入</Button>
          方案一：(库存优先)
        </p>
        <p class="remark">PS: 系统将根据现有可用站点，自动平均分布到各个站点</p> -->

        <div v-for="(item,index) in recommendData" :key="index">
          <p class="p-b-5 p-t-10 p-l-5">
            <Button type="success" class="m-r-5" size="small" :disabled="cuurentCountdown==='已过期'||submiting" @click="handlePlan2(item.planId)">一键加入</Button>
            方案{{formatNumberToChinese(index+1)}}： ({{item.dimension}})
          </p>
          <div class="recommend-background">
            <p class="p-b-5"><span class="title">历史拟合度</span> <span :class="item.dimension.indexOf('客户')>-1?'text-orange':''">{{item.historySimilarity}} %</span></p>
            <p class="p-b-5"><span class="title">行业拟合度</span> <span :class="item.dimension.indexOf('行业')>-1?'text-orange':''">{{item.industrySimilarity}} %</span></p>
            <p class="p-b-5"><span class="title">覆盖人流量</span> <span :class="item.dimension.indexOf('客流')>-1?'text-orange':''">{{formatNumber(item.flow)}} 人次</span></p>

            <p>
              <span class="title">推荐站点</span>
              <a class="m-l-10" v-for="(station,index) in item.planResourceList" :key="index" @click="handleShowResource(station, item.planId)">
                {{station.stationName}} ({{station.resourceIdList.length}})
              </a>
            </p>
          </div>
        </div>

        <Spin fix size="large" v-if="spinShow">
          <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
          <div>数据生成中...</div>
        </Spin>

        <SvgStation ref="svgStation" :stationSvgModal.sync="svgModal.isShow" :orderId="svgModal.orderId" :stationId="svgModal.stationId"
          :searchSchedules="svgModal.schedule" :svgShowType="svgModal.svgShowType" showStationPortray
          @svg-finished="svgOrThreeFinished" @handleChange3dMode="handleBeginRevice3d"/>

        <ThreeStation ref="threeStation" @three-finished="svgOrThreeFinished" @handleChangeSvgMode="handleShowResource"/>
    </Modal>
</template>

<script>
import SvgStation from '@/components/svg/SvgStation'
import ThreeStation from '@/components/svg/ThreeStation'

import { getDynamicPlan } from '@/api/product/product'
import { NoToChinese, PrefixInteger } from '@/utils/index'
import { toMoney } from '@/utils/wnumb_own'

export default {
  components: {
    SvgStation, ThreeStation
  },
  data () {
    return {
      spinShow: false,
      submiting: false,
      showRecommend: false,
      recommendData: [],
      stationsList: [],
      svgModal: {
        isShow: false,
        orderId: 0,
        stationName: '',
        stationId: 0,
        schedule: [],
        svgShowType: 3 // 3订单详情资源展示
      },
      choosePlanId: 0,
      cuurentCountdown: '',
      lastestTime: null,
      intervalID: null
    }
  },
  methods: {
    initData (searchCondition) {
      this.svgModal.schedule = JSON.parse(searchCondition.schedules)
      this.svgModal.orderId = searchCondition.orderId
      this.showRecommend = true
      this.spinShow = true
      this.submiting = false

      getDynamicPlan(searchCondition).then(res => {
        this.recommendData = res
        if (res.length > 0) {
          const now = new Date()
          this.lastestTime = now.setSeconds(now.getSeconds() + res[0].validSecond)
          this.loadCountdown()
        }
        this.spinShow = false
      }).catch(() => {
        this.spinShow = false
        this.showRecommend = false
      })
    },
    loadCountdown () {
      this.intervalID = setInterval(() => {
        var lefttime = this.lastestTime - new Date().getTime() // 距离结束时间的毫秒数

        var leftd = Math.floor(lefttime / (1000 * 60 * 60 * 24)) // 计算天数
        var lefth = Math.floor(lefttime / (1000 * 60 * 60) % 24) // 计算小时数
        var leftm = Math.floor(lefttime / (1000 * 60) % 60) // 计算分钟数
        var lefts = Math.floor(lefttime / 1000 % 60) // 计算秒数

        const showDays = leftd > 0 ? (leftd + '天') : ''
        if (lefth <= 0 && leftm <= 0 && lefts <= 0) {
          this.cuurentCountdown = '已过期'
          clearInterval(this.intervalID)
        } else { // 返回倒计时的字符串
          this.cuurentCountdown = showDays + PrefixInteger(lefth, 2) + ':' + PrefixInteger(leftm, 2) + ':' + PrefixInteger(lefts, 2)
        }
      }, 1000)
    },
    handleShowResource (station, planId) {
      this.svgModal.isShow = true
      if (station.stationId) {
        this.svgModal.stationId = station.stationId
        this.svgModal.stationName = station.stationName

        this.choosePlanId = planId
      }
    },
    svgOrThreeFinished () {
      const planResourceList = this.recommendData.find(r => r.planId === this.choosePlanId).planResourceList
      const recommendResourceList = planResourceList.filter(r => r.stationId === this.svgModal.stationId)
      const tagResourceList = []
      recommendResourceList.forEach(element => {
        element.deviceList.forEach(deviceBean => {
          tagResourceList.push({
            deviceId: deviceBean.deviceId,
            stationId: element.stationId,
            floor: deviceBean.floor
          })
        })
      })

      if (this.svgModal.isShow) {
        this.$refs.svgStation.tagResource(tagResourceList)
        this.$refs.svgStation.choseSpinShow()
      } else {
        this.$refs.threeStation.tagResource(tagResourceList)
      }
    },
    handlePlan1 () { // 方案一
      this.showRecommend = false
      this.$emit('handlePlan1')
    },
    handlePlan2 (planId) { // 方案二
      this.submiting = true
      this.$emit('handlePlan2', planId)
      this.showRecommend = false
    },
    formatNumberToChinese (number) {
      return NoToChinese(number)
    },
    formatNumber (number) {
      return toMoney(number).replace('¥', '').replace('.00', '')
    },
    handleBeginRevice3d (stationId) {
      this.$refs.threeStation.initData(stationId)
    }
  },
  watch: {
    showRecommend (val) {
      if (!val) {
        clearInterval(this.intervalID)
        this.cuurentCountdown = ''
      }
    }
  }
}
</script>
